<script lang="ts">
  import ThemeViewState from "../../Models/ThemeViewState"
  import { Accordion } from "flowbite-svelte"
  import LayerStatistics from "./LayerStatistics.svelte"

  /**
   * An element showing s
   */
  export let state: ThemeViewState
  let layers = state.theme.layers.filter((l) => l.isNormal())
</script>

<Accordion>
  {#each layers as layer (layer.id)}
    <LayerStatistics {state} {layer} />
  {/each}
</Accordion>
