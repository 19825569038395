<script lang="ts">
  import { TagsFilter } from "../../Logic/Tags/TagsFilter"
  import FromHtml from "../Base/FromHtml.svelte"
  import { Translation } from "../i18n/Translation"
  import Tr from "../Base/Tr.svelte"
  import Translations from "../i18n/Translations"

  /**
   * A 'TagHint' will show the given tags in a human readable form.
   * Depending on the options, it'll link through to the wiki or might be completely hidden
   */
  export let tags: TagsFilter[]
  export let currentProperties: Record<string, string> = {}
</script>

{#if tags?.length > 0}
  {#each tags as tag}
    <div class="break-words" style="word-break: break-word">
      {#if tag["value"] === ""}
        <del>
          {tag["key"]}
        </del>
      {:else}
        <FromHtml src={tag.asHumanString(true, false, currentProperties)} />
      {/if}
    </div>
  {/each}
{:else}
  <slot name="no-tags">
    <Tr cls="subtle" t={Translations.t.general.noTagsSelected} />
  </slot>
{/if}
