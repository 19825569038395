<script lang="ts">
  import { UIEventSource } from "../../Logic/UIEventSource"
  import type { OsmTags } from "../../Models/OsmFeature"
  import type { Feature } from "geojson"
  import LayerConfig from "../../Models/ThemeConfig/LayerConfig"
  import Translations from "../i18n/Translations"
  import Tr from "../Base/Tr.svelte"
  import NearbyImages from "./NearbyImages.svelte"
  import Popup from "../Base/Popup.svelte"
  import ThemeViewState from "../../Models/ThemeViewState"
  import { onDestroy } from "svelte"
  import { MenuState } from "../../Models/MenuState"
  import { CloseButton } from "flowbite-svelte"

  export let tags: UIEventSource<OsmTags>
  export let state: ThemeViewState
  export let lon: number
  export let lat: number
  export let feature: Feature

  export let linkable: boolean = true
  export let layer: LayerConfig
  const t = Translations.t.image.nearby

  let enableLogin = state.featureSwitches.featureSwitchEnableLogin
  export let shown = new UIEventSource(false)
  onDestroy(
    MenuState.nearbyImagesFeature.addCallback((something) => {
      if (something !== feature) {
        shown.set(false)
      }
    })
  )
  onDestroy(
    shown.addCallbackAndRun((isShown) => {
      if (isShown) {
        MenuState.nearbyImagesFeature.set(feature)
      }
    })
  )
</script>

{#if enableLogin.data}
  <button
    on:click={() => {
      shown.set(!shown.data)
    }}
  >
    <Tr t={t.seeNearby} />
  </button>
  <Popup {shown} bodyPadding="p-4" dismissable={false}>
    <Tr slot="header" t={t.seeNearby} />
    <CloseButton slot="closebutton" on:click={() => shown?.set(false)} />
    <NearbyImages {tags} {state} {lon} {lat} {feature} {linkable} {layer} />
  </Popup>
{/if}
