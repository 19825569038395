<script lang="ts">
  /**
   * Statistics, based on the tagRendering, for a single layer
   */
  import LayerConfig from "../../Models/ThemeConfig/LayerConfig"
  import Tr from "../Base/Tr.svelte"
  import Loading from "../Base/Loading.svelte"
  import ToSvelte from "../Base/ToSvelte.svelte"
  import TagRenderingChart from "../BigComponents/TagRenderingChart"
  import type { Feature } from "geojson"
  import { AccordionItem } from "flowbite-svelte"
  import ThemeViewState from "../../Models/ThemeViewState"
  import DefaultIcon from "../Map/DefaultIcon.svelte"

  export let layer: LayerConfig
  export let state: ThemeViewState
  let bbox = state.mapProperties.bounds
  let elements: Feature[] = state.perLayer.get(layer.id).GetFeaturesWithin($bbox)
  $: elements = state.perLayer.get(layer.id).GetFeaturesWithin($bbox)

  let trs = layer.tagRenderings.filter((tr) => tr.question)
</script>

<AccordionItem
  paddingDefault="p-2"
  inactiveClass="text-black"
  defaultClass="w-full flex-grow justify-start"
>
  <div slot="header" class="flex items-center gap-x-2">
    <div class="inline-block h-8 w-8">
      <DefaultIcon {layer} />
    </div>
    <Tr t={layer.name} />
    ({elements.length} elements in view)
  </div>

  {#if elements === undefined}
    <Loading />
  {:else if elements.length === 0}
    No features in view
  {:else}
    <div class="flex w-full flex-wrap gap-x-4 gap-y-4">
      {#each trs as tr}
        <ToSvelte
          construct={() =>
            new TagRenderingChart(elements, tr, {
              chartclasses: "w-full self-center",
              includeTitle: true,
            }).SetClass(tr.multiAnswer ? "w-128" : "w-96")}
        />
      {/each}
    </div>
  {/if}
</AccordionItem>
