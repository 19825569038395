<script lang="ts">
  import { UIEventSource } from "../../../Logic/UIEventSource"
  import tag2link from "tag2link/index.json"

  export let tags: UIEventSource<Record<string, any>>
  export let key: string

  const tag2linkData = tag2link as Tag2LinkElement[]

  interface Tag2LinkElement {
    /**
     * OSM tag key
     */
    key: string

    /**
     * URL template or formatter URL: replace `$1` with the tag value
     */
    url: string

    /**
     * Source of this formatter URL
     */
    source?: string

    /**
     * Rank or relative importance of this formatter URL.
     */
    rank?: "preferred" | "normal" | "deprecated"
  }

  const url = tag2linkData.find((item) => item.key === `Key:${key}`)?.url
</script>

{#if url}
  <a
    href={url.replace("$1", $tags[key])}
    target="_blank"
    rel="noopener noreferrer"
    class="tag-link"
  >
    {$tags[key]}
  </a>
{:else}
  {$tags[key]}
{/if}
